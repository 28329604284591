<template>
  <div>


    <div class=" " @click="() => { setEditingTrue() }">
      <span class="text-white w-full " :class="{ 'text-md': !textsm, 'font-bold': !textsm }">{{ valueLabel }}</span>
    </div>

    <div v-if="taskId != 0 /*&& editing == true*/" class="my-4">
      <span v-if="inputError != ''" class="label-text-alt text-red-400">{{ $t(inputError) }}</span>

      <div v-if="readonly == false">

        <!-- static FUP version -->

        <div v-for="op in options" v-bind:key="op.value + op.label" class="flex flex-row">
          <RemPrimaryButtonSmall :label=" op.label" class="my-1" :method="() => {
                                    saveTaskState(op)
                                }"></RemPrimaryButtonSmall>
          

        </div>


        <!--
        LINK VERSION

        <div v-for="op in options" v-bind:key="op.value + op.label" class="flex flex-row">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            class="bi bi-arrow-right-short mr-2 my-auto" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
          </svg>
          <a @click="saveTaskState(op)" class="valueRow">{{ op.label }}</a>

        </div>
        -->

        <!--
        
        ORIGINAL VERZIOM
        
        <select v-model="transition" class="input input-sm input-bordered w-full" :mandatory="mandatory"
          @focusout="editing = false" id="statusEditField">
          <option value="" selected>{{ $t('message.select') }}</option>

          <option v-for="op in options" v-bind:key="op.value + op.label" :value="op.value">

            {{ op.label }}

          </option>
        </select>-->

      </div>
    </div>

  </div>
</template>

<script setup>
import { ref, defineModel, onMounted, watch, computed } from 'vue';
import Axios from "@/misc/axios";
//import store from "@/misc/vuex-store";
import RemPrimaryButtonSmall from '../RemPrimaryButtonSmall.vue';

import TenantConfigHandler from '@/misc/TenantConfigHandler';
//import SVGRenderer from '../../SVGRenderer.vue';
import { useRoute } from "vue-router";
import toast from "@/misc/toast.js";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

let route = useRoute();

const props = defineProps(['title', 'type', 'helperText', 'mandatory', 'textsm', 'taskId']);
const inputValue = defineModel('inputValue');
const valueLabel = defineModel('valueLabel');
const readonlyViews = ['edit']

const editing = ref(false);
const readonly = computed(() => {
  route.params.view;
  return readonlyViews.includes(route.params.view)
})


const options = ref([])

//const transition = ref('')

const inputError = ref("");

const taskId = ref(props.taskId ? props.taskId : route.params.taskId)

onMounted(() => {
  setMeUp()
})

const setMeUp = () => {

  if (taskId.value == 0) {

    inputValue.value = 0,
      valueLabel.value = "Új érdeklődés"
  } else {
    //transition.value = '';
    Axios.get(TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/workflow/task/${taskId.value}/available-transitions`, {
      onTokenRefreshed: () => {
        setMeUp();
      },
    }).then((resp) => {
      if (resp && resp.status == 200) {
        console.log(resp);

        options.value.splice(0, options.value.length)
        if (resp.data.content.length > 0) {
          resp.data.content.forEach((op) => {

            options.value.push({
              label: op.actionName,
              value: op.id,
              name: op.toStateName,
              toStateId: op.toStateId,
            });
          });
        }
      }
    })
  }
  //if(approvalStates.includes(inputValue.value)){}

}

/*const setFocus = function () {

  setTimeout(() => { focus.value = false }, 500);
}*/

const setEditingTrue = function () {
  if (readonlyViews.includes(route.params.view)) {
    return;
  }
  if (editing.value == false) {
    editing.value = true;
    setTimeout(() => {
      document.getElementById(`statusEditField`)?.focus()
    }, 500);
  }else{
    editing.value = false
  }


}

const saveTaskState = async (transition) => {
  try {
    const resp = await Axios.put(TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/workflow/task/${taskId.value}/transition/${transition.value}`, {
      onTokenRefreshed: () => {
        saveTaskState();
      },
    })

    if (resp && resp.status == 200) {
      console.log(resp);
      valueLabel.value = transition.name;
      inputValue.value = transition.toStateId

      setMeUp()
      toast.success(t("message.successfulSave"));
    }
  } catch (e) {

    toast.error(t("message.notSuccessfulSave"));
  }
  editing.value = false
}

//watchers

/*watch(() => transition.value, (newValue, oldValue) => {
  console.log('inputChange', newValue, oldValue);

  if (newValue == "") return;
  saveTaskState()


})*/

watch(() => props.taskId, (newValue, oldValue) => {
  if (oldValue == 0 || oldValue != newValue) {
    taskId.value = newValue;
    setMeUp()
  }

})

watch(() => route.params.taskId, (newValue, oldValue) => {

  if (oldValue == 0) {
    taskId.value = newValue;
    setMeUp()
  }

})



</script>

<style scoped>
/*.wf-title {

  padding: 10px 0 10px 0;
  border-radius: 10px;
}

.wf-title:hover {
  background-color: rgba(0, 0, 0, .3);

}*/

.im-dropdown-content {
  position: relative
}

.im-invisible {
  display: none;

}

.im-mandatory {
  color: red;
}

.valueRow:hover {
  font-weight: bolder;
  cursor: pointer;
}
</style>