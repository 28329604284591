<template>
  <div id="ReportCreationPage" class="h-screen">
    <BaseLayout>
      <template #mainContent>
        <div class="h-fit px-2 overflow-y-auto mx-30 k">
          <!--Header-->

          <header class="w-full cardBg">
            <div class="items-start px-6 pb-6 mx-auto md:flex-row">
              <div class="flex justify-between">
                <p class="flex mb-4 text-xl text-secondary md:mb-0 uppercase font-bold">
                  Riport
                </p>
              </div>
            </div>
          </header>

          <div class="pl-5">
            <button @click="reporting" class="btn btn-lg btn-secondary normal-case text-md im-round-btn mb-8">
              <SpinnerLoader sizeClasses="w-5 h-5" v-if="state.loading" />
              <span v-else>{{ $t("message.reportCreationModule") }}</span>
            </button>
          </div>

        </div>
      </template>
    </BaseLayout>
  </div>
</template>

<script setup>
import SpinnerLoader from "@/components/SpinnerLoader.vue";
import BaseLayout from "@/layouts/BaseLayout.vue";
import TenantConfigHandler from "@/misc/TenantConfigHandler";
import axios from "@/misc/axios.js";
import Utils from "../misc/Utils";
import { ref } from "vue";

const state = ref({
  loading: false,
})

const reporting = () => {
  state.value.loading = true;
  const uri = TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/reporting/";
  try {
    axios
      .get(
        uri,
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        var url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: response.data.type,
          })
        );
        var link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", Utils.parseContentDispositionToFileName(response.headers["content-disposition"]));
        document.body.appendChild(link);
        link.click();
        state.value.loading = false;
      });
  } catch (error) {
    console.log(error);
    state.value.loading = false;
  }
}


</script>

<style scoped>
.cardBg {
  background-color: hsl(220, 17.647%, 20%);
}

.im-main-card {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
  padding: 3rem;
  width: 100%;
  max-width: 100%;
  height: fit-content;
  overflow-x: auto;
}

@media screen and (max-width: 600px) {
  .im-main-card {
    margin-bottom: 30px;
  }
}

.im-main-card-starter {
  animation: 0.5s ease-out 0s 1 scaleupY;

}

.im-main-card-starter div {
  animation: 1.5s ease-out 0s 1 fadein;
}

@keyframes scaleupY {
  0% {
    transform-origin: left top;
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

.im-sidemenu-contanier {
  animation: 0.5s ease-out 0s 1 scaleupY;
  padding: 1rem;

  margin-right: 2rem;
  height: fit-content;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
}

.im-sidemenu-contanier div {
  animation: 1.5s ease-out 0s 1 fadein;
}

.im-card-form {
  padding-top: 2rem;
}

.fade-enter-active {
  transition: opacity 1s ease 1s, padding 1s ease 1s, width 1s ease 1s;
}

.fade-leave-active {
  transition: opacity 0.5s ease, padding 0.5s ease, width 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  padding: 0;
  width: 0;
  opacity: 0;
}

.im-round-btn {
  border-radius: 9999px;
}

/* button */

.btn-circle {
  transition: width 0.5s;
}

.im-sidemenu-contanier:hover .btn-circle {
  width: 15rem;
}

@keyframes fadeinbtn {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

.im-btn-text {
  display: none;
}

.im-sidemenu-contanier:hover .im-btn-text {
  animation: 0.5s ease-out 0s 1 fadeinbtn;
  display: inline;
}

.im-btn-icon {
  display: inline;
}

.im-button-active {
  border-color: lighter hsl(var(--s));
}

.im-sidemenu-contanier:hover .im-btn-icon {
  display: none;
}
</style>