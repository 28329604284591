let uniqueField = "propertiesGrid";
import TenantConfigHandler from '@/misc/TenantConfigHandler';
import store from "@/misc/vuex-store";

export default (state, t, toPropertyFn, basicDataTypeMapping, basicDataClassificationMapping, basicDataStatusMapping) => {
  return {
    endpoint: TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/property`,
    idkey: "id",
    defaultOrderKey: "id",
    defaultOrderDirection: "desc",
    orderLocal: false,
    rootkey: "content",
    pagination: {
      gridUniqueId: uniqueField,
      active: true,
      page: 0,
      size: 10,
      possiblePageSizes: [10, 20, 50, 100]
    },
    filtering: {
      active: true,
      local: false,
      //simple: true,
      //simpleHelpText: l('rvg-simple-filter-help-text'),
    },
    bulkOperation: {
      active: false,
    },
    columnSelector: {
      active: true,
      gridUniqueId: uniqueField,
      mode: "server", // server or local/null
      serverGridKey: "worksGridColumns",
    },
    xlsxExport: {
      active: true,
    },
    refreshable: {
      manualActive: true,
      autoActive: true,
      autoCanBeDisabled: true,
      gridUniqueId: uniqueField,
      autoValues: [
        {
          key: "30sec",
          refreshInterval: 30,
          title: t("message.rvg-automatic-list-refresh-30-sec"),
          default: true,
        },
        {
          key: "1min",
          refreshInterval: 60,
          title: t("message.rvg-automatic-list-refresh-1-min"),
        },
        {
          key: "5min",
          refreshInterval: 300,
          title: t("message.rvg-automatic-list-refresh-5-min"),
        },
      ],
    },
    mapping: {
      $operations: {
        title: "",
        visible: true,
        filterable: false,
        selectable: false,
        orderable: false,
        width: "50px",
        formatter: {
          type: "Operations",
          config: {
            buttons: (vm, callMeToRefreshTheGrid, rowid, record) => {
              var buttons = [];

              buttons.push({
                title: "",
                tooltip: t("message.open"),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4"><path stroke-linecap="round" stroke-linejoin="round" d="M12,8V4L20,12L12,20V16H4V8H12Z" /></svg>',
                classList:
                  "btn btn-secondary btn-sm hover:!opacity-80 btn-circle",
                testValueAttribute: "propertyForwardButton",
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  toPropertyFn(record.id);
                },
              });

              buttons.push({
                title: "",
                tooltip: t("message.edit"),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4"><path stroke-linecap="round" stroke-linejoin="round" d="M12 3L2 12H5V20H10V14H14V15.11L19.43 9.68L12 3M21.04 11.14C20.9 11.14 20.76 11.2 20.65 11.3L19.65 12.3L21.7 14.35L22.7 13.35C22.91 13.14 22.91 12.79 22.7 12.58L21.42 11.3C21.32 11.2 21.18 11.14 21.04 11.14M19.06 12.88L13 18.94V21H15.06L21.11 14.93L19.06 12.88Z" /></svg>',
                classList:
                  "btn btn-secondary btn-sm hover:!opacity-80 btn-circle",
                testValueAttribute: "propertyEditButton",
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  state.value.propertyId = record.id;
                  state.value.addressMainValidation = false
                  state.value.propertyBasicData.forEach((e) => {
                    if (e.type == "label") return;
                    if (e.type == "option") {
                      e.value =
                        record[
                          "basicData" +
                            e.model.charAt(0).toUpperCase() +
                            e.model.slice(1) +
                            "Id"
                        ];
                      e.valueLabel =
                        record[
                          "basicData" +
                            e.model.charAt(0).toUpperCase() +
                            e.model.slice(1) +
                            "Value"
                        ];
                    } else {
                      e.value =
                        record[
                          "basicData" +
                            e.model.charAt(0).toUpperCase() +
                            e.model.slice(1)
                        ];
                    }
                  });
                  store.commit("setRemFormEdit", false);
                  state.value.tab = 3;
                },
              });

              return buttons;
            },
          },
        },
      },
      "id": {
        title: "ID",
        visible: false,
        selectable: false, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Number'
        },
        exportable: false,
        orderable: true,
      },
      "primaryAddressCompleteAddress": {
        title: t("message.address"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.primaryAddressCompleteAddress
            ? record.primaryAddressCompleteAddress
            : "N/A";
        },
      },
      "basicDataPropertySheetAppellation": {
        title: t("message.propertySheetAppellation"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataPropertySheetAppellation
            ? record.basicDataPropertySheetAppellation
            : "N/A";
        },
      },
      "basicDataFloorArea": {
        title: t("message.floorArea"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Number",
        },
        exportable: true,
        orderable: true,
      },
      "basicDataKng": {
        title: t("message.kng"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataKng ? record.basicDataKng : "N/A";
        },
      },
      "basicDataFloc": {
        title: t("message.floc"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataFloc ? record.basicDataFloc : "N/A";
        },
      },
      "basicDataSap": {
        title: t("message.sap"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataSap ? record.basicDataSap : "N/A";
        },
      },
      "basicDataTypeValue": {
        title: t("message.nature"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataTypeValue ? record.basicDataTypeValue : "N/A";
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: basicDataTypeMapping,
        },
      },
      "basicDataProtection": {
        title: t("message.protection"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        formatter: {
          type: 'Status',
          dataType: "boolean", // could be: integer, text, boolean
          mapping: {
            "true": {
              title: t('message.yes')
            },
            "false": {
              title: t('message.no')
            },
            "null": {
              title: 'N/A'
            }
          }
        }
      },
      "basicDataProtectionNote": {
        title: t("message.protectionNote"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataProtectionNote
            ? record.basicDataProtectionNote
            : "N/A";
        },
      },
      "basicDataNumberDedicated": {
        title: t("message.numberDedicated"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Number",
        },
        exportable: true,
        orderable: true,
      },
      "basicDataNumberCanBeSeated": {
        title: t("message.numberCanBeSeated"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Number",
        },
        exportable: true,
        orderable: true,
      },
      "basicDataNumberSeated": {
        title: t("message.numberSeated"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Number",
        },
        exportable: true,
        orderable: true,
      },
      "basicDataClassificationValue": {
        title: t("message.classification"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataClassificationValue
            ? record.basicDataClassificationValue
            : "N/A";
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: basicDataClassificationMapping,
        },
      },
      "basicDataStatusValue": {
        title: t("message.status"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataStatusValue
            ? record.basicDataStatusValue
            : "N/A";
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: basicDataStatusMapping,
        },
      },
      "basicDataSolarPanel": {
        title: t("message.solarPanel"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        formatter: {
          type: 'Status',
          dataType: "boolean", // could be: integer, text, boolean
          mapping: {
            "true": {
              title: t('message.yes')
            },
            "false": {
              title: t('message.no')
            },
            "null": {
              title: 'N/A'
            }
          }
        }
      },
      "basicDataFuelCellGenerator": {
        title: t("message.fuelCellGenerator"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        formatter: {
          type: 'Status',
          dataType: "boolean", // could be: integer, text, boolean
          mapping: {
            "true": {
              title: t('message.yes')
            },
            "false": {
              title: t('message.no')
            },
            "null": {
              title: 'N/A'
            }
          }
        }
      },
      "basicDataDieselGenerator": {
        title: t("message.dieselGenerator"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        formatter: {
          type: 'Status',
          dataType: "boolean", // could be: integer, text, boolean
          mapping: {
            "true": {
              title: t('message.yes')
            },
            "false": {
              title: t('message.no')
            },
            "null": {
              title: 'N/A'
            }
          }
        }
      },
      "basicDataEnergyTradingPartner": {
        title: t("message.energyTradingPartner"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        formatter: {
          type: 'Status',
          dataType: "boolean", // could be: integer, text, boolean
          mapping: {
            "true": {
              title: t('message.yes')
            },
            "false": {
              title: t('message.no')
            },
            "null": {
              title: 'N/A'
            }
          }
        }
      },
    },
  };
};
