<template>
  <div>
    <input
      type="text"
      class="input input-sm input-bordered w-full"
      :value="formattedValue"
      @input="onInput"
      @blur="onBlur"
      :id="`${title}number`"
      step="any"
      autocomplete="off"
    />
  </div>
</template>

<script setup>
import { defineModel, computed, ref } from "vue";

const props = defineProps(["title", "validationMethod"]);
const emit = defineEmits(["update:inputError"]);

const inputValue = defineModel("inputValue");
const inputError = defineModel("inputError");
const inputErrorVariables = defineModel("inputErrorVariables");

const lastValidValue = ref(inputValue.value == null? '' : inputValue.value.toString());

const formattedValue = computed(() => {
  return lastValidValue.value.toString().replace('.', ',');
});

const onInput = (event) => {
  const rawValue = event.target.value;
  
  if (rawValue === '') {
    inputValue.value = null; 
    lastValidValue.value = '';
    emit("update:inputError", "");
    return;
  }

  if (rawValue === '-') {
    inputValue.value = null; 
    lastValidValue.value = '-';
    emit("update:inputError", "");
    return;
  }

  let normalizedValue = rawValue.replace(',', '.');

  // Prevent leading zeros except for '0,' or '-0,'
  if (/^-?0\d/.test(normalizedValue)) {
    event.target.value = lastValidValue.value.replace('.', ',');
    emit("update:inputError", "message.numberOnly");
    return;
  }
  
  if (/^-?\d+(\.\d*)?$/.test(normalizedValue)) {
    inputValue.value = parseFloat(normalizedValue);
    lastValidValue.value = normalizedValue;
    emit("update:inputError", "");
  } else {
    event.target.value = lastValidValue.value.replace('.', ',');
    emit("update:inputError", "message.numberOnly");
  }
};

const onBlur = (event) => {
  console.log(props.validationMethod);
  if (props.validationMethod) {
    props.validationMethod(
      [],
      [],
      "",
      event.target.value,
      "",
      inputValue,
      inputError,
      inputErrorVariables
    );
  } else {
    emit("update:inputError", "");
  }
};

</script>