let uniqueField = "propUtilizationsGrid";

import TenantConfigHandler from '@/misc/TenantConfigHandler';
import store from "@/misc/vuex-store";

export default (t, state, buildingId, legalRelationshipMapping, /*partnerMainTypeMapping, partnerSubTypeMapping*/) => {
  return {
    endpoint: TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/utilization/building/${buildingId}`,
    idkey: "id",
    defaultOrderKey: "id",
    defaultOrderDirection: "desc",
    orderLocal: false,
    rootkey: "content",
    pagination: {
      gridUniqueId: uniqueField,
      active: true,
      page: 0,
      size: 10,
      possiblePageSizes: [10, 20, 50, 100]
    },
    filtering: {
      active: true,
      local: false,
      //simple: true,
      //simpleHelpText: l('rvg-simple-filter-help-text'),
    },
    bulkOperation: {
      active: false,
    },
    columnSelector: {
      active: true,
      gridUniqueId: uniqueField,
      mode: "server", // server or local/null
      serverGridKey: "worksGridColumns",
    },
    xlsxExport: {
      active: true,
    },
    refreshable: {
      manualActive: true,
      autoActive: true,
      autoCanBeDisabled: true,
      gridUniqueId: uniqueField,
      autoValues: [
        {
          key: "30sec",
          refreshInterval: 30,
          title: t("message.rvg-automatic-list-refresh-30-sec"),
          default: true,
        },
        {
          key: "1min",
          refreshInterval: 60,
          title: t("message.rvg-automatic-list-refresh-1-min"),
        },
        {
          key: "5min",
          refreshInterval: 300,
          title: t("message.rvg-automatic-list-refresh-5-min"),
        },
      ],
    },
    mapping: {
      "$operations": {
        title: "",
        visible: true,
        filterable: false,
        selectable: false,
        orderable: false,
        width: "50px",
        formatter: {
          type: "Operations",
          config: {
            buttons: (vm, callMeToRefreshTheGrid, rowid, record) => {
              var buttons = [];

              buttons.push({
                title: '',
                tooltip: t('message.edit'),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4"><path stroke-linecap="round" stroke-linejoin="round" d="M12 3L2 12H5V20H10V14H14V15.11L19.43 9.68L12 3M21.04 11.14C20.9 11.14 20.76 11.2 20.65 11.3L19.65 12.3L21.7 14.35L22.7 13.35C22.91 13.14 22.91 12.79 22.7 12.58L21.42 11.3C21.32 11.2 21.18 11.14 21.04 11.14M19.06 12.88L13 18.94V21H15.06L21.11 14.93L19.06 12.88Z" /></svg>',
                classList: 'btn btn-secondary btn-sm hover:!opacity-80 btn-circle',
                testValueAttribute: 'utilizationEditButton',
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  state.value.utilizationEdit = true;
                  state.value.utilizationId = record.id;
                    state.value.utilizationData.forEach((e) => {
                      if (e.type == "label") return;
                      if (e.type == "option") {          
                        e.value = record[e.model + "Id"];
                        e.valueLabel = record[e.model + "Value"];
                      }else if (e.type == "optionapi" || e.type == "optionSearchEndpoint") {
                        e.value = record[e.model];
                        e.valueLabel = record[e.model.replace('Id', 'Name')];
                      } else {
                        e.value = record[e.model];
                      }
                    });
                    store.commit("setRemTab", 11)
                    store.commit("setRemFormEdit", false);
                }
              })

              return buttons;
            },
          },
        },
      },
      "id": {
        title: "ID",
        visible: false,
        selectable: false, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Number'
        },
        exportable: false,
        orderable: true,
      },
      "floorArea": {
        title: t("message.floorArea"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Number",
        },
        exportable: true,
        orderable: true,
      },
      "legalRelationshipValue": {
        title: t("message.legalRelationship"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.legalRelationshipValue
            ? record.legalRelationshipValue
            : "N/A";
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: legalRelationshipMapping,
        },
      },
      "legalRelationshipStart": {
        title: t("message.legalSituationStart"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Date",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.legalRelationshipStart
            ? record.legalRelationshipStart
            : "N/A";
        },
      },
      "legalRelationshipEnd": {
        title: t("message.legalSituationEnd"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Date",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.legalRelationshipEnd
            ? record.legalRelationshipEnd
            : "N/A";
        },
      },
      "partnerName": {
        title: "Partner",
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.partnerName ? record.partnerName : "N/A";
        },
      },
      // "partnerTaxId": {
      //   title: t("message.taxId"),
      //   visible: true,
      //   selectable: true, // non-optional, must be selected
      //   filterable: {
      //     active: true,
      //     type: "Text",
      //   },
      //   exportable: true,
      //   orderable: true,
      //   middleware: (value, record) => {
      //     return record?.partnerTaxId ? record.partnerTaxId : "N/A";
      //   },
      // },
      // "partnerTaxNumber": {
      //   title: t("message.taxNumber"),
      //   visible: true,
      //   selectable: true, // non-optional, must be selected
      //   filterable: {
      //     active: true,
      //     type: "Text",
      //   },
      //   exportable: true,
      //   orderable: true,
      //   middleware: (value, record) => {
      //     return record?.partnerTaxNumber ? record.partnerTaxNumber : "N/A";
      //   },
      // },
      // "partnerMainTypeValue": {
      //   title: "Partner " + t("message.mainType"),
      //   visible: true,
      //   selectable: true, // non-optional, must be selected
      //   filterable: {
      //     active: true,
      //     type: "Status",
      //   },
      //   exportable: true,
      //   orderable: true,
      //   middleware: (value, record) => {
      //     return record?.partnerMainTypeValue
      //       ? record.partnerMainTypeValue
      //       : "N/A";
      //   },
      //   formatter: {
      //     type: "Status",
      //     dataType: "text",
      //     mapping: partnerMainTypeMapping,
      //   },
      // },
      // "partnerSubTypeValue": {
      //   title: "Partner " + t("message.subType"),
      //   visible: true,
      //   selectable: true, // non-optional, must be selected
      //   filterable: {
      //     active: true,
      //     type: "Status",
      //   },
      //   exportable: true,
      //   orderable: true,
      //   middleware: (value, record) => {
      //     return record?.partnerSubTypeValue
      //       ? record.partnerSubTypeValue
      //       : "N/A";
      //   },
      //   formatter: {
      //     type: "Status",
      //     dataType: "text",
      //     mapping: partnerSubTypeMapping,
      //   },
      // },
    },
  };
};
