export default {
  data: [
    {
      title: "manufacturer",
      value: "",
      model: "manufacturer",
      type: "text",
    },
    {
      title: "guarantee",
      value: "",
      model: "guarantee",
      type: "date",
    },
    {
      title: "powerProduced",
      value: "",
      model: "powerProduced",
      type: "number",
    },
    {
      title: "annualPerformance",
      value: "",
      model: "annualPerformance",
      type: "number",
    },
    {
      title: "performanceTransfer",
      value: "",
      model: "performanceTransfer",
      type: "checkbox",
    },
    {
      title: "accounting",
      value: "",
      valueLabel: "",
      model: "accounting",
      type: "option",
      optionValue: "SOLAR_PANEL_ACCOUNTING"
    },
    {
      title: "numberOfSolarPanels",
      value: "",
      model: "number",
      type: "number",
    },
    {
      title: "performanceOfSolarPanels",
      value: "",
      model: "performance",
      type: "number",
    },
    {
      title: "installTime",
      value: "",
      model: "installDate",
      type: "date",
    },
    {
      title: "inverterPerformance",
      value: "",
      model: "inverterPerformance",
      type: "number",
    },
    {
      title: "inverterManufacturer",
      value: "",
      model: "inverterManufacturer",
      type: "text",
    },
    {
      title: "inverterType",
      value: "",
      model: "inverterType",
      valueLabel: "",
      type: "option",
      optionValue: "SOLAR_PANEL_INVERTER_TYPE"
    },
  ]
}