<template>
    <div :class = "{ hideOpenIndicator : inputValue }">
        <div  class="dropdown w-full">
            <select v-model="inputValue" class="input input-sm input-bordered w-full" @focusout="focus = false" :id="`${title}${type}`">
                <!-- <option selected hidden value="">{{ $t('message.select') }}</option> -->
                    <!-- <option value="null">{{ $t('message.empty') }}</option> -->
                <option value="true">{{ $t('message.yes') }}</option>
                <option value="false">{{ $t('message.no') }}</option>
            </select>
            <button
                v-if="inputValue" class="clear-button" @click="inputValue = ''" aria-label="Törlés">
                <span class="text-sm bold">
                    X
                </span>
            </button>
        </div>
    </div>
</template>

<script setup>
import {defineModel } from 'vue';

defineProps(['type', 'title']);

const inputValue = defineModel('inputValue')
const focus = defineModel('focus')
</script>

<style scoped>

.dropdown {
    position: relative;
    display: inline-flex;
  align-items: center;
    width: 100%;
}

.clear-button {
  position: absolute;
  right: 0.6rem;
  background: none;
  border: none;
  color: rgb(175, 170, 170);
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  line-height: 1;
}

.clear-button:hover {
  cursor: pointer;
}

.hideOpenIndicator .dropdown::before {
  display: none;
}

.dropdown::before {
    position: absolute;
    font-size: 12px;
    content: " \25BC";
    top: 7px;
    right: -8px;
    height: 20px;
    width: 28px;
    pointer-events: none;
}

select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}
</style>