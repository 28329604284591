import TenantConfigHandler from "@/misc/TenantConfigHandler";

export default {
  data: [
    {
      title: "tasks",
      value: "",
      valueLabel: "",
      model: "task",
      type: "optionapi",
      endPoint:
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/workflow/task",
      displayValue: "subject",
    },
    {
      title: "responsible",
      value: "",
      valueLabel: "",
      model: "responsible",
      type: "optionSearchEndpoint",
      optionSearchEndpoint: "uploader",
    },
    {
      value: "",
      title: "deadline",
      model: "deadline",
      type: "date",
      viewList: ["read", "new","newfromlist", "edit"],
      stateList: [],
      readonlyViewList: [],
      readonlyStateList: [],
    },
    {
      value: "",
      title: "comment",
      model: "comment",
      type: "textarea",
      viewList: ["read", "new", "newfromlist","edit"],
      stateList: [],
      readonlyViewList: [],
      readonlyStateList: [],
    },
  ],
};
