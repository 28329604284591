// import TenantConfigHandler from "@/misc/TenantConfigHandler";

export default {
  premiseData: [
    {
      title: "function",
      value: "",
      valueLabel: "",
      model: "premiseFunction",
      type: "option",
      optionValue: "PREMISE_FUNCTION",
    },
    {
      title: "cremClassification",
      value: "",
      valueLabel: "",
      model: "cremClass",
      type: "option",
      optionValue: "CREM_CLASSIFICATION",
    },
    {
      title: "identifier",
      value: "",
      model: "identifier",
      type: "text",
      helperText: "codeFHelperText"
    },
    {
      title: "level",
      value: "",
      valueLabel: "",
      model: "floor",
      type: "option",
      optionValue: "PREMISE_FLOOR",
    },
    {
      title: "buildingPagePremiseNumber",
      value: "",
      model: "premiseNumber",
      type: "text",
    },
    {
      title: "floorArea",
      value: "",
      model: "floorArea",
      type: "number",
    },
    {
      title: "isAccessible",
      value: "",
      model: "accessibility",
      type: "checkbox",
    },
  ],
  staircaseData: [
    {
      title: "stairwayId",
      value: "",
      model: "basicDataStairwayIdentifier",
      type: "text",
    },
    {
      title: "stairwayType",
      value: "",
      model: "basicDataStairwayType",
      type: "option",
      optionValue: "STAIRWAY_TYPE",
    },
    {
      title: "isEscapeRoute",
      value: "",
      model: "basicDataFireRoute",
      type: "checkbox",
    },
    {
      title: "isSmokeFree",
      value: "",
      model: "basicDataSmokeFree",
      type: "checkbox",
    },
  ],
  elevatorData: [
    {
      title: "numberOfPerson",
      value: "",
      model: "basicDataElevatorNumberPersons",
      type: "text",
    },
    {
      title: "elevatorType",
      value: "",
      valueLabel: "",
      model: "basicDataElevatorType",
      type: "option",
      optionValue: "ELEVATOR_TYPE",
    },
    {
      title: "loadCapacity",
      value: "",
      model: "basicDataElevatorLoadCapacity",
      type: "number",
    },
    {
      title: "isEmergencyElevator",
      value: "",
      model: "basicDataEmergencyElevator",
      type: "checkbox",
    },
  ],
  legalsitData: [
    {
      title: "legalSituation",
      value: "",
      valueLabel: "",
      model: "legalSituationType",
      type: "option",
      optionValue: "LEGAL_SITUATION",
    },
    {
      title: "legalRelationship",
      value: "",
      valueLabel: "",
      model: "legalRelationshipType",
      type: "option",
      optionValue: "LEGAL_RELATIONSHIP_TYPE",
    },
    {
      title: "ownership",
      value: "",
      model: "ownership",
      type: "text",
    },
    {
      title: "legalRelationshipStart",
      value: "",
      model: "legalRelationshipStart",
      type: "date",
    },
    {
      title: "legalRelationshipEnd",
      value: "",
      model: "legalRelationshipEnd",
      type: "date",
    },
    {
      title: "floorArea",
      value: "",
      model: "floorArea",
      type: "number",
    },
  ],
  utilizationData: [
    {
      title: "floorArea",
      value: "",
      model: "floorArea",
      type: "number",
      validated: true,
    },
    {
      title: "legalRelationship",
      value: "",
      model: "legalRelationship",
      type: "option",
      optionValue: "UTILIZATION_LEGAL_RELATIONSHIP",
      validated: true,
    },
    {
      title: "legalRelationshipStart",
      value: "",
      model: "legalRelationshipStart",
      type: "date",
      validated: true,
    },
    {
      title: "legalRelationshipEnd",
      value: "",
      model: "legalRelationshipEnd",
      type: "date",
      validated: true,
    },
    {
      title: "partner",
      value: "",
      optionSearchEndpoint: "partner",
      model: "partnerId",
      type: "optionSearchEndpoint",
    },
    // {
    //   title: "partner",
    //   value: "",
    //   model: "partnerId",
    //   type: "optionapi",
    //   mandatory: true,
    //   validated: true,
    //   endPoint: TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/partner",
    //   displayValue: "name",
    // },
  ],
  meterData: [
    {
      title: "meterType",
      value: "",
      valueLabel: "",
      model: "type",
      type: "option",
      optionValue: "METER_TYPE",
    },
    {
      title: "contractingParty",
      value: "",
      optionSearchEndpoint: "partner",
      model: "partnerId",
      type: "optionSearchEndpoint",
    },
    {
      title: "publicUtilityType",
      value: "",
      model: "utilityType",
      type: "option",
      optionValue: "METER_UTILITY_TYPE",
    },
    {
      title: "podIdentifier",
      value: "",
      model: "identifier",
      type: "text",
    },
    {
      title: "meterPartnerType",
      value: "",
      valueLabel: "",
      model: "meterPartnerType",
      type: "option",
      optionValue: "METER_PARTNER_TYPE",
    },
    {
      title: "serialNumber",
      value: "",
      model: "serialNumber",
      type: "text",
    },
    {
      title: "placement",
      value: "",
      model: "placement",
      type: "text",
    },
    {
      title: "commType",
      value: "",
      type: "option",
      model: "commType",
      optionValue: "METER_COMM_TYPE",
    },
  ],
  
  partnerData: [
    {
      title: "name",
      value: "",
      model: "name",
      type: "text",
    },
    {
      title: "mainType",
      value: "",
      model: "partnerMainType",
      type: "option",
      optionValue: "PARTNER_MAIN_TYPE",
    },
    {
      title: "subType",
      value: "",
      model: "partnerSubType",
      type: "option",
      optionValue: "PARTNER_SUB_TYPE",
      onchangeMethod: (options, optionsRepo, value, inputValue,) => {
        options.value = optionsRepo.filter((elem) => elem.parent == value);
        if (options.value.filter((elem) => elem.value == inputValue.value)) {
          inputValue.value = "";
        } 
      },
    },
    {
      title: "privateIndividual",
      value: "",
      model: "",
      type: "checkbox",
    },
    {
      title: "taxId",
      value: "",
      model: "taxId",
      type: "text",
      visibilityCondition: (newValue/*, oldValue*/) => {
        return newValue === "true"
      }
    },
    {
      title: "taxNumber",
      value: "",
      model: "taxNumber",
      type: "text",
      visibilityCondition: (newValue/*, oldValue*/) => {
        return newValue === "false"
      },
      validationMethod: (options, optionsRepo, value, newValue, oldValue, inputValue, inputError) => {
        
        if(newValue.length == 8 && oldValue.length < 8){
          inputValue.value = inputValue.value + "-"
        }
        else if(newValue.length == 10 && oldValue.length < 10){
          inputValue.value = inputValue.value + "-"
        }
        else if(newValue.length > 13){
          inputError.value = "tulhosszuAdoszam"
        }
      }
    },
  ],
};
