import UserRoles from "./UserRoles";

export default {
  [UserRoles.NRGMAP_USER]: [
    "MainMap",
    "MapView",
    "Heatmap",
    // 'Devices', // Uncomment this line to enable the Devices page for NRGMAP_USER
    "TemperatureMapView",
  ],
  [UserRoles.NRGMAP_ADMIN]: [
    "MainMap",
    "MapView",
    "Heatmap",
    "Devices",
    "ZoneAdmin",
    "TemperatureMapView",
    "ViewGists",
    "AddNewGist",
    "EditGist",
    "DeleteGist",
    "MessageEditor",
    'ViewReports',
    "AddNewSiteDesign",
    "EditSiteDesign",
    "DeleteSiteDesign",
    "EditMetaData"
  ],
  [UserRoles.NRGMAP_REM_USER]: [
    "MainMap",
    "MapView",
    "RemUser",
    "DocumentsPage",
    "PropertiesPage",
    "PremisePage",
    "EnergeticsPage",
    "BuildingPage",
    "PartnerPage",
    
  ],
  [UserRoles.NRGMAP_REM_ADMIN]: [
    "RemDelete",
    "MainMap",
    "MapView",
    "RemUser",
    "RemAdmin",
    "DocumentsPage",
    "PropertiesPage",
    "PremisePage",
    "EnergeticsPage",
    "BuildingPage",
    "PartnerPage",
    "VocabularyAdmin",
    "MessageEditor",
    "ReportCreationPage",
  ],

  [UserRoles.NRGMAP_SITE_ADMIN]: [
    "AddNewSiteDesign",
    "EditSiteDesign",
    "DeleteSiteDesign",
  ],
  [UserRoles.NRGMAP_REPORTS_USER]: [
    "ViewReports",
  ],

  [UserRoles.NRGMAP_WORKFLOW_USER]: [
    "WorkflowUser",
  ],

  [UserRoles.NRGMAP_WORKFLOW_ADMIN]: [
    "WorkflowUser",
  ],
};
