<template>
    <div>
        <WFLink :method="() => { state.open = !state.open }" class="uppercase mb-4">{{ $t('message.relatedTasks') }}
        </WFLink>

        <div v-if="state.open == true">
            <input type="hidden" v-model="state.taskToAdd" class="input input-sm input-bordered w-full"
                :placeholder="$t('message.select')">
            <div class="im-dropdown w-full">

                <!-- eslint-disable -->
                <WFTitleField v-model:inputValue="state.taskToAdd" :config="state.relatedtaskFieldConfig"
                    :textsm="true"></WFTitleField>
                <WFLabelField v-model:inputValue="state.relationToAdd" :config="state.relationFieldConfig">
                </WFLabelField>
                <!-- eslint-enable -->

            </div>
            <RemPrimaryButtonSmall class="my-4" :label="$t('message.add')" :method="addThisTask">
            </RemPrimaryButtonSmall>
            <div>
                <div v-for="task in state.relatedTaskArray" v-bind:key="task.id"
                    class="my-4 related-task-card p-2 rounded-md">
                    <div class="flex flex-row justify-between align-center">
                        <p class="text-white font-bold comment-link" @click="task.openTab = !task.openTab">{{ task.id }} : {{ task.subject }}</p>
                        <!--<RemPrimaryButtonSmall v-if="task.openTab == false "
                            :label="$t('message.open')" :method="() => { task.openTab = true }">
                        </RemPrimaryButtonSmall>
                        <RemPrimaryButtonSmall v-if="task.openTab == true" :label="$t('message.close')"
                            :method="() => { task.openTab = false }">
                        </RemPrimaryButtonSmall>-->
                    </div>

                    <div v-if="task.openTab == true" >
                        <!-- eslint-disable -->
                        <WFLabelField v-model:inputValue="task.relation" :config="state.relationFieldConfig">
                        </WFLabelField>
                        <!-- eslint-enable -->
                        <RemPrimaryButtonSmall :label="$t('message.save')" :method="() => { }" class="my-2">
                        </RemPrimaryButtonSmall>
                        <div v-for="field in relatedTaskFieldList" v-bind:key="field[0]">
                            <p>{{ $t(`message.${field[1]}`) + ": " + ((task[field[0]] == null || task[field[0]] ==
                                undefined || task[field[0]] == "") ? "N/A" : task[field[0]]) }}</p>
                        </div>
                        <RemPrimaryButtonSmall :label="$t('message.delete')" :method="() => { removeThisTask(task.id) }"
                            class="my-2">
                        </RemPrimaryButtonSmall>
                    </div>
                </div>
            </div>

        </div>




    </div>
</template>

<script setup>
import RemPrimaryButtonSmall from '../../components/REM/RemPrimaryButtonSmall.vue';
import WFLink from '../../components/REM/Workflow/WFLink.vue';
import WFTitleField from '../../components/REM/Workflow/WFLabelField.vue';
import WFLabelField from '../../components/REM/Workflow/WFLabelField.vue';

import { defineModel, ref } from 'vue';
import workflowTaskUtils from './workflowTaskUtils';
import { useRoute } from "vue-router";
import axios from "@/misc/axios.js";
import TenantConfigHandler from '@/misc/TenantConfigHandler';

const route = useRoute();

const relatedTaskIds = defineModel('relatedTaskIds')



const state = ref({})

state.value.taskId = route.params.taskId
state.value.taskRepo = [];
state.value.optionSearchValue = ""
state.value.relatedTaskArray = []
state.value.focus = false
state.value.options = []
state.value.taskToAdd = ""
state.value.realtionToAdd = ""
state.value.open = false
state.value.relationFieldConfig = {
    title: 'taskRelationType',
    type: 'optionManual',
    manualOptions: [
        {
            value: 0,
            label: 'alárendelt'
        },
        {
            value: 1,
            label: 'fölérendelt'
        }
    ]
}

state.value.relatedtaskFieldConfig = {
    title: 'taskToRelate',
    type: 'optionManual',
    manualOptions: state.value.taskRepo
}



const relatedTaskFieldList = [['type', 'taskType'], ['identifier', 'identifier'], ['priorityValue', 'priority'], ['responsibleName', 'responsible'], ['stateName', 'state']]

workflowTaskUtils.getTasks(state, relatedTaskIds);

const addThisTask = async () => {

    if (state.value.taskId == 0) {
        relatedTaskIds.value.push(state.value.taskToAdd);
        const resp = await axios.get(
            TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
            "/rem/workflow/task/" +
            state.value.taskToAdd,
            {
                onTokenRefreshed: () => {
                    addThisTask();
                },
            }
        );

        if (resp && resp.status == 200) {
            resp.data.openTab = false
            state.value.relatedTaskArray.push(resp.data)
        }
        state.value.taskRepo.every((e, i) => {
            

            if (e.value == state.value.taskToAdd) {
                state.value.taskRepo.splice(i, 1)
                return false
            }
            return true
        });

        state.value.taskToAdd = ""
        state.value.options = state.value.taskRepo
    } else {
        try {
            const hermes = relatedTaskIds.value.concat([state.value.taskToAdd])
            let resp = await axios.put(TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
                `/rem/workflow/task/${state.value.taskId}/related-tasks`, { relatedTaskIds: hermes },
                {
                    onTokenRefreshed: () => {
                        addThisTask();
                    },
                });

            if (resp && (resp.status == 200 || resp.status == 201)) {
                relatedTaskIds.value.push(state.value.taskToAdd);
                resp.data.openTab = false
                state.value.relatedTaskArray.push(resp.data)
                state.value.taskRepo.every((e, i) => {
                    
                    if (e.value == state.value.taskToAdd) {
                        state.value.taskRepo.splice(i, 1)
                        return false
                    }
                    return true
                });
                state.value.taskToAdd = ""
                state.value.options = state.value.taskRepo

            }
        } catch (e) {
            console.log(e);



        }
    }


}


const removeThisTask = async (id) => {



    if (state.value.taskId == 0) {
        relatedTaskIds.value.forEach((e, i) => {
            if (e == id) {
                relatedTaskIds.value.splice(i, 1)
            }
        });

        state.value.relatedTaskArray.forEach((e, i) => {
            if (e.id == id) {
                state.value.relatedTaskArray.splice(i, 1)
            }
        });
    } else {
        try {
            const hermes = []
            relatedTaskIds.value.forEach((e) => {
                if (e != id) {
                    hermes.push(e)
                }
            });
            let resp = await axios.put(TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
                `/rem/workflow/task/${state.value.taskId}/related-tasks`, { relatedTaskIds: hermes },
                {
                    onTokenRefreshed: () => {
                        removeThisTask;
                    },
                });

            if (resp && (resp.status == 200 || resp.status == 201)) {
                relatedTaskIds.value.forEach((e, i) => {
                    if (e == id) {
                        relatedTaskIds.value.splice(i, 1)
                    }
                });

                state.value.relatedTaskArray.forEach((e, i) => {
                    if (e.id == id) {
                        state.value.relatedTaskArray.splice(i, 1)
                    }
                });


                state.value.taskRepo.push({
                    value: resp.data.id,
                    label: resp.data.subject,
                });
                state.value.options = state.value.taskRepo

            }
        } catch (e) {
            console.log(e);



        }
    }

}

//watchers



</script>

<style>
.im-dropdown-content {
    position: relative
}

.comment-link:hover {
    color: #e18330
}

.comment-link-active {
    color: #e18330
}

.wf-title:hover {
    background-color: rgba(0, 0, 0, .3);

}

.related-task-card {
    background-color: rgba(0, 0, 0, .3);

}
</style>