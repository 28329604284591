<template>
    <div id="WorkflowMainPage" class="h-screen max-h-screen">
        <BaseLayout>
            <template #leftButtons>

            </template>

            <template #mainContent>
                <div class="h-full h-max-full mx-auto px-2  k">
                    <div>
                        <RemFormBackbutton :method="() => { moveAwayConfirm(); }"></RemFormBackbutton>
                        <h2 class="text-lg font-sans font-semibold uppercase text-white mb-8">
                            {{ $t(`message.taskMainTitle_${route.params.view}`) }}
                        </h2>
                    </div>
                    <div class="flex flex-row">
                        <!-- MAIN CARD -->
                        <TransitionGroup name="fade">
                            <RemFormCard class="mr-8 w-8/12" key="mainCard">
                                <template #remcardheader>
                                    <!-- eslint-disable -->
                                    <WFTitleFieldViewWraped v-model:taskState="state.taskData.state.value"
                                        v-model:formData="state.taskData.subject" textsm="false">
                                    </WFTitleFieldViewWraped>
                                    <WFTitleFieldViewWraped v-model:taskState="state.taskData.state.value"
                                        v-model:formData="state.taskData.type" textsm="false"></WFTitleFieldViewWraped>
                                    <WFTitleFieldViewWraped v-model:taskState="state.taskData.state.value"
                                        v-model:formData="state.taskData.identifier" textsm="false">
                                    </WFTitleFieldViewWraped>

                                    <!-- eslint-enable -->

                                    <div class="flex flex-row my-4">
                                        <!-- eslint-disable -->
                                        <ViewWrapper v-model:taskState="state.taskData.state.value"
                                            :viewList="workflowAccessMartix.cancelButtonConfig.viewList"
                                            :stateList="workflowAccessMartix.cancelButtonConfig.stateList"
                                            :readonlyViewList="workflowAccessMartix.cancelButtonConfig.readonlyViewList"
                                            :readonlyStateList="workflowAccessMartix.cancelButtonConfig.readonlyStateList"
                                            defaultReadonly="false">
                                            <RemPrimaryButtonSmall :method="() => { moveAwayConfirm(); }"
                                                :label="$t('message.cancel')" class="mr-2" />
                                        </ViewWrapper>


                                        <RemPrimaryButtonSmall
                                            v-if="state.taskMainValidation == true && route.params.view != 'read'"
                                            :label="$t('message.save')" :method="saveTask" class="mr-2">
                                        </RemPrimaryButtonSmall>

                                        <ViewWrapper v-model:taskState="state.taskData.state.value"
                                            :viewList="workflowAccessMartix.editButtonConfig.viewList"
                                            :stateList="workflowAccessMartix.editButtonConfig.stateList"
                                            :readonlyViewList="workflowAccessMartix.editButtonConfig.readonlyViewList"
                                            :readonlyStateList="workflowAccessMartix.editButtonConfig.readonlyStateList"
                                            defaultReadonly="false">
                                            <RemPrimaryButtonSmall :label="$t('message.edit')" :method="() => {
                                                router.push({
                                                    name: 'WorkflowTaskPage',
                                                    params: { taskId: taskId, view: 'edit' }
                                                })
                                            }" />

                                        </ViewWrapper>

                                    </div>

                                </template>
                                <template #remcardmain>

                                    <!-- eslint-disable -->
                                    <WFTitleFieldViewWraped v-model:taskState="state.taskData.state.value"
                                        v-model:formData="state.taskData.note" textsm="true"></WFTitleFieldViewWraped>

                                    <!-- eslint-enable -->

                                    <div>
                                        <!-- eslint-disable -->
                                        <ViewWrapper v-model:taskState="state.taskData.state.value"
                                            :viewList="workflowAccessMartix.relatedTaskConfig.viewList"
                                            :stateList="workflowAccessMartix.relatedTaskConfig.stateList"
                                            :readonlyViewList="workflowAccessMartix.relatedTaskConfig.readonlyViewList"
                                            :readonlyStateList="workflowAccessMartix.relatedTaskConfig.readonlyStateList"
                                            defaultReadonly="false">
                                            <TaskRelatedTasks v-model:relatedTaskIds="state.relatedTaskIds">
                                            </TaskRelatedTasks>
                                        </ViewWrapper>
                                       
                                        <!-- eslint-enable -->
                                    </div>


                                    <!-- COMMENTS -->
                                    <div v-if="workflowAccessMartix.activitiesConfig.viewList.includes(route.params.view)" class="my-4">
                                        <TaskComments></TaskComments>


                                    </div>

                                </template>
                            </RemFormCard>

                            <!-- DETAILS CARD -->
                            <RemFormCard class="w-4/12" key="detailsCard">
                                <template #remcardheader>
                                    <!-- eslint-disable -->
                                    <ViewWrapper v-model:taskState="state.taskData.state.value"
                                        v-model:readonly="state.taskData.state.readonly"
                                        :viewList="state.taskData.state.viewList"
                                        :readonlyViewList="state.taskData.state.readonlyViewList"
                                        :defaultReadonly="state.taskData.state.defaultReadonly">
                                        <WFTransitionField v-model:inputValue="state.taskData.state.value"
                                            v-model:valueLabel="state.taskData.state.valueLabel"
                                            v-model:saveEvent="state.saveEvent" />
                                    </ViewWrapper>


                                    <!-- eslint-enable -->

                                </template>
                                <template #remcardmain>

                                    <!-- DETAILS  -->
                                    <WFLink :method="() => { state.openDetails = !state.openDetails }"
                                        class="uppercase mb-4">{{ $t('message.details') }}</WFLink>

                                    <div v-if="state.openDetails == true">

                                        <!-- eslint-disable -->
                                        <LabelFieldListWState v-model:formData="state.taskData"
                                            :fieldArray="['responsible', 'priority', 'deadline', 'createdBy', 'createdAt']">
                                        </LabelFieldListWState>
                                        <!-- eslint-enable -->

                                    </div>
                                    <!-- eslint-disable -->
                                    <WFFormAccordion v-model:taskType="state.taskData.type.value"
                                        v-model:taskState="state.taskData.state.value"
                                        v-model:customFieldSaveEvent="state.customFieldSaveEvent"
                                        v-model:validation="state.customValidations.approver"
                                        groupFormData="approverGroup" />
                                    <!--
                                        <WFFormAccordion v-model:taskType="state.taskData.type.value"
                                        v-model:taskState="state.taskData.state.value"
                                        v-model:customFieldSaveEvent="state.customFieldSaveEvent"
                                        groupFormData="approvalDates" />
                                        -->
                                    <!-- eslint-enable -->
                                </template>
                            </RemFormCard>
                        </TransitionGroup>

                    </div>
                    <!-- eslint-disable -->
                    <RemFormConfirmationModal v-model:showConfirmation="state.confirmation.showConfirmation"
                        v-model:confirmationTextToken="state.confirmation.confirmationTextToken"
                        v-model:confirmationTrueMethod="state.confirmation.confirmationTrueMethod"
                        v-model:confirmationFalseMethod="state.confirmation.confirmationFalseMethod">
                    </RemFormConfirmationModal>
                    <!-- eslint-enable -->



                    <div style="height: 10rem"></div>
                </div>





            </template>
        </BaseLayout>
    </div>
</template>

<script setup>
//components
import BaseLayout from '../layouts/BaseLayout.vue'
import WFTitleFieldViewWraped from '../components/REM/Workflow/WFTitleFieldViewWraped.vue';
import WFLink from '../components/REM/Workflow/WFLink.vue';
import RemFormCard from '../components/REM/RemFormCard.vue';
import RemFormBackbutton from '../components/REM/RemFormBackbutton.vue';
import RemPrimaryButtonSmall from '../components/REM/RemPrimaryButtonSmall.vue';
import LabelFieldListWState from '../components/REM/Workflow/LabelFieldListWState.vue';
import TaskComments from './WorkflowPageComponents/TaskComments.vue';
import TaskRelatedTasks from './WorkflowPageComponents/TaskRelatedTasks.vue';
import RemFormConfirmationModal from '../components/REM/RemFormConfirmationModal.vue';
import WFTransitionField from '../components/REM/Workflow/WFTransitionField.vue';
import ViewWrapper from '../components/REM/Workflow/ViewWrapper.vue';
import WFFormAccordion from '../components/REM/Workflow/WFFormAccordion.vue';

// utils

import { ref, watch } from 'vue';

import TenantConfigHandler from '@/misc/TenantConfigHandler';
import { useRoute } from "vue-router";
import router from "@/misc/router";
import workflowTaskUtils from './WorkflowPageComponents/workflowTaskUtils.js';
import { useI18n } from "vue-i18n";
import workflowAccessMartix from './WorkflowPageComponents/workflowAccessMartix.js';


//formdata
import taskFormData from "../misc/remFormdata/workflow/taskData.js";
//import taskCommentData from "../misc/remFormdata/workflow/taskCommentData.js";


let route = useRoute();
const { t } = useI18n();

const state = ref({});

state.value.taskData = {};



state.value.taskId = route.params.taskId
workflowAccessMartix.checkAccess(state, route.params.view)


state.value.openDetails = true
state.value.relatedTaskIds = [];
state.value.confirmation = {
    showConfirmation: false,
    confirmationTextToken: "",
    confirmationTrueMethod: () => { },
    confirmationFalseMethod: () => { },
}
state.value.saveEvent = 0;
state.value.customFieldSaveEvent = {
    counter: 0,
    id: route.params.taskId
};

state.value.mainTitle = '';

state.value.customValidations = {
    approver: false
}

taskFormData.data.forEach((e) => {
    e.value = "";
    e.valueLabel = ""
    state.value.taskData[e.model] = e;
})

if (state.value.taskId != 0) {
    workflowTaskUtils.getTask(state)
} else {
    state.value.taskData.priority.value = "MEDIUM"
    state.value.taskData.priority.valueLabel = t("message.PriorityMedium")
}

state.value.taskMainValidation = false;

const saveTask = async () => {

    const hermes = {
        priority: state.value.taskData.priority.value,
        typeId: state.value.taskData.type.value,
        identifier: state.value.taskData.identifier.value,
        subject: state.value.taskData.subject.value,
        stateId: state.value.taskData.state.value == null ? 1 : state.value.taskData.state.value,
        deadline: state.value.taskData.deadline.value,
        note: state.value.taskData.note.value,
        responsibleUserId: state.value.taskData.responsible.value,
        relatedTaskIds: []
    };

    state.value.relatedTaskIds.forEach((e) => {
        hermes.relatedTaskIds.push(e)
    })

    const resp = await workflowTaskUtils.saveUniversal(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/workflow/task",
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/workflow/task/" + state.value.taskId,
        hermes,
        state.value.taskId,
        t)
    state.value.customFieldSaveEvent.counter = state.value.customFieldSaveEvent.counter + 1;
    state.value.customFieldSaveEvent.id = resp.data.id;
    if (state.value.taskId == 0) {
        state.value.taskId = resp.data.id
        state.value.saveEvent = state.value.saveEvent + 1;

        state.value.taskData.createdBy.value = resp.data.createdByName;
        state.value.taskData.createdAt.value = resp.data.createdAt;
        state.value.taskData.state.value = resp.data.stateId;
        state.value.taskData.state.valueLabel = resp.data.stateName;
        state.value.taskData.identifier.value = resp.data.identifier;
    }

    setTimeout(() => {
            router.push({
                name: "WorkflowTaskPage",
                params: { taskId: resp.data.id, view: "read" },
            });
        }, 200)

}

const moveAwayConfirm = () => {
    const readonlyWiews = ['read'];
    if (readonlyWiews.includes(route.params.view)) {
        router.push({
            name: 'WorkflowMainPage'
        })
    } else {
        state.value.confirmation.confirmationTextToken = route.params.view == "new" ? "abortTaskCreate" : "abortTaskEdit";
        state.value.confirmation.confirmationTrueMethod = () => {
            state.value.confirmation.showConfirmation = false;
            router.push({
                name: 'WorkflowMainPage'
            })
        };
        state.value.confirmation.confirmationFalseMethod = () => {
            state.value.confirmation.showConfirmation = false;
        };
        state.value.confirmation.showConfirmation = true;
    }

}

/*const deleteTaskConfirm = () => {
    state.value.confirmation.confirmationTextToken = "deleteConfirm";
    state.value.confirmation.confirmationTrueMethod = () => {
        deleteTask()
        state.value.confirmation.showConfirmation = false;
    };
    state.value.confirmation.confirmationFalseMethod = () => {
        state.value.confirmation.showConfirmation = false;
    };
    state.value.confirmation.showConfirmation = true;
}*/

watch(() => route.params.view, () => {

    workflowAccessMartix.checkAccess(state, route.params.view)

})

for (const [e] of Object.entries(state.value.taskData)) {


    if ((state.value.taskData[e].validated == true || state.value.taskData[e].validated == undefined) && (state.value.taskData[e].mandatory == false || state.value.taskData[e].mandatory == undefined)) continue;

    watch(() => state.value.taskData[e].validated,
        () => {
            checkTaskValidation();
        });
}
for (const [e] of Object.entries(state.value.customValidations)) {

    watch(() => state.value.customValidations[e],
        () => {

            checkTaskValidation();
        });
}


const checkTaskValidation = () => {
    let lever = true;


    for (const [e] of Object.entries(state.value.taskData)) {
        if ((state.value.taskData[e].mandatory == false || state.value.taskData[e].mandatory == undefined)) continue;
        if (state.value.taskData[e].validated === false) {

            lever = false

        }

    }

    for (const [e] of Object.entries(state.value.customValidations)) {
        if (state.value.customValidations[e] == undefined) continue;
        if (state.value.customValidations[e] == false) {

            lever = false

        }


    }


    state.value.taskMainValidation = lever
}

setTimeout(() => {
    checkTaskValidation();
}, 3000)


</script>

<style scoped>
.comment-link:hover {
    color: #e18330
}

.comment-link-active {
    color: #e18330
}

.cardBg {
    background-color: hsl(220, 17.647%, 20%);
}

.im-main-card {
    --tw-bg-opacity: 1;
    background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
    padding: 3rem;

    max-width: 100%;
    overflow-x: auto;
    height: fit-content;
}

.im-main-card-starter {
    animation: 0.5s ease-out 0s 1 scaleupY;

}

.im-main-card-starter div {
    animation: 1.5s ease-out 0s 1 fadein;
}

@keyframes scaleupY {
    0% {
        transform-origin: left top;
        transform: scaleY(0);
    }

    100% {
        transform: scaleY(1);
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 100%;
    }
}

.fade-enter-active {
    transition: opacity 1s ease 1s, padding 1s ease 1s, width 1s ease 1s;
}

.fade-leave-active {
    transition: opacity 0.5s ease, padding 0.5s ease, width 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    padding: 0;
    width: 0;
    opacity: 0;
}
</style>