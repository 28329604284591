

export default {
    title: 'approvers',
    groupName: "Jóváhagyók",
    typeIds : [5],
    data: [],
    form: [
        {
            id: "",
            customFieldId: "1",
            backendType: "NUMBER",
            backendName: "userName: ",
            title: 'first_approval_user_form',
            model:'FIRST_APPROVAL_USER_ID',
            //type:'text',
            type: 'optionSearchEndpoint',
            optionSearchEndpoint: "uploader",
            value: '',
            
        
        },
        {
            id: "",
            customFieldId: "2",
            backendType: "NUMBER",
            backendName: "userName: ",
            title: 'second_approval_user_form',
            model:'SECOND_APPROVAL_USER_ID',
            type: 'optionSearchEndpoint',
            optionSearchEndpoint: "uploader",
            value: ''
        },
       
        

    ]
}