//import Axios from "@/misc/axios";
//import toast from "@/misc/toast.js";
import TenantConfigHandler from '@/misc/TenantConfigHandler';
import store from "@/misc/vuex-store";

let uniqueField = "legalsitGrid";

/*let l = (key) => {
  // @TODO: Implement localization
  return key;
};*/

export default (premiseId, state, t, legalRelationshipTypeMapping, legalSituationTypeMapping) => {
  return {
    endpoint: TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/legal-situation/premise/${premiseId}`,
    idkey: "id",
    defaultOrderKey: "id",
    defaultOrderDirection: "desc",
    orderLocal: false,
    rootkey: "content",
    pagination: {
      gridUniqueId: uniqueField,
      active: true,
      page: 0,
      size: 10,
      possiblePageSizes: [10, 20, 50, 100],
    },
    filtering: {
      active: true,
      //local: false,
      //premiseId: premiseId,
      //simpleHelpText: l('rvg-simple-filter-help-text'),
    },
    bulkOperation: {
      active: false,
    },
    columnSelector: {
      active: true,
      gridUniqueId: uniqueField,
      mode: "server", // server or local/null
      serverGridKey: "worksGridColumns",
    },
    xlsxExport: {
      active: true,
    },
    refreshable: {
      manualActive: true,
      autoActive: true,
      autoCanBeDisabled: true,
      gridUniqueId: uniqueField,
      autoValues: [
        {
          key: "30sec",
          refreshInterval: 30,
          title: t("message.rvg_automatic_list_refresh_30_sec"),
          default: true,
        },
        {
          key: "1min",
          refreshInterval: 60,
          title: t("message.rvg_automatic_list_refresh_1_min"),
        },
        {
          key: "5min",
          refreshInterval: 300,
          title: t("message.rvg_automatic_list_refresh_5_min"),
        },
      ],
    },
    mapping: {
      $operations: {
        title: "",
        visible: true,
        filterable: false,
        selectable: false,
        orderable: false,
        width: "50px",
        formatter: {
          type: "Operations",
          config: {
            buttons: (vm, callMeToRefreshTheGrid, rowid, record) => {
              var buttons = [];

              buttons.push({
                title: "",
                tooltip: t("message.open"),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4"><path stroke-linecap="round" stroke-linejoin="round" d="M12 3L2 12H5V20H10V14H14V15.11L19.43 9.68L12 3M21.04 11.14C20.9 11.14 20.76 11.2 20.65 11.3L19.65 12.3L21.7 14.35L22.7 13.35C22.91 13.14 22.91 12.79 22.7 12.58L21.42 11.3C21.32 11.2 21.18 11.14 21.04 11.14M19.06 12.88L13 18.94V21H15.06L21.11 14.93L19.06 12.88Z" /></svg>',
                classList: "btn btn-secondary btn-circle btn-sm hover:!opacity-80",
                testValueAttribute: "propertyEditButton",
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  state.value.legalsitId = record.id;
                  state.value.legalsitData.forEach((e) =>{
                    if(e.model == "building"){
                      e.value = record.buildingId
                      e.valueLabel = record.buildingId
                    }else if(e.model == "property"){
                      e.value = record.propertyId
                      e.valueLabel = record.propertyId
                    }else{
                      e.value = record[e.model]
                    }

                    if(e.type == "option"){
                      e.value = record[e.model + "Id"]
                      e.valueLabel = record[e.model + "Value"]
                    }

                  })
                  store.commit("setRemTab", 8)
                  store.commit("setRemFormEdit", false)
                },
              });

              /*buttons.push({
                title: "",
                tooltip: t('message.delete'),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4"><path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" /></svg>',
                classList:
                  "btn btn-secondary btn-circle btn-sm hover:!opacity-80",
                testValueAttribute: "propertyEditButton",
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                 Axios.delete(
                  TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
                    "/rem/legal-situation/" +
                    record.id,
                ).then((resp) => {
                  console.log(resp);
                  if(resp.status== 204){
                    toast.info(t("message.deleteSuccessful")) 
                  }
                  else{
                    toast.info(t("message.deleteNotSuccessful"))
                  }
                });
                },
              });*/

              return buttons;
            },
          },
        },
      },
      "id": {
        title: "ID",
        visible: false,
        selectable: false, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Number'
        },
        exportable: false,
        orderable: true,
      },
      "propertyId": {
        title: t("message.property"),
        visible: false,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: "Text",
        },
        exportable: false,
        orderable: true,
        middleware: (value, record) => {
          return record.propertyId ? record.propertyId : "N/A";
        },
      },
      "buildingId": {
        title: t('message.building'),
        visible: false,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: "Text",
        },
        exportable: false,
        orderable: true,
        middleware: (value, record) => {
          return record.buildingId ? record.buildingId : "N/A";
        },
      },
      "legalRelationshipTypeValue": {
        title: t("message.legalRelationship"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Status'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record ? record.legalRelationshipTypeValue : 'N/A'
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: legalRelationshipTypeMapping,
        },
      },
      "legalSituationTypeValue": {
        title: t("message.legalSituation"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Status'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record ? record.legalSituationTypeValue : 'N/A'
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: legalSituationTypeMapping,
        },
      },
      "legalRelationshipStart": {
        title: t("message.legalRelationshipStart"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Date",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record.legalRelationshipStart
            ? new Date(record.legalRelationshipStart).toLocaleString()
            : "N/A";
        },
      },
      "legalRelationshipEnd": {
        title: t("message.legalRelationshipEnd"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Date",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record.legalRelationshipEnd ? new Date(record.legalRelationshipEnd).toLocaleString() : "N/A";
        },
      },
      "ownership": {
        title: t("message.ownership"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Text'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record ? record.ownership : 'N/A'
        }
      },
      "floorArea": {
        title: t("message.floorArea"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Number",
        },
        exportable: true,
        orderable: true,
      },
    },
  };
};
