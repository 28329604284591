import Axios from "@/misc/axios";
import toast from "@/misc/toast.js";
import router from "@/misc/router.js";
import { watchEffect } from "vue";
import TenantConfigHandler from '@/misc/TenantConfigHandler';
import store from '@/misc/vuex-store.js'

export default {
  setup: (state) => {
    if (state.value.tab == 2) {
      watchEffect(() => {
      if (state.value.propertyAddress[2].value) {
        //console.log("postal");
        setTimeout(() => {
          state.value.propertyAddress[3].value =
            "CHANGECODE###" + state.value.propertyAddress[2].value;
          state.value.propertyAddress[4].value =
            "CHANGECODE###" + state.value.propertyAddress[2].value;
          state.value.propertyAddress[5].value =
          "CHANGECODE###" + state.value.propertyAddress[2].value;
        }, 700);
      }
    });
    }
    
  },
  toProperty: function (id) {
    router.push({
      name: "property-buildings",
      params: { propertyId: id },
    });
    store.commit("setRemTab", 1)
  },
  saveProperty: async function (state, t) {
    const hermes = {
      basicData: {
        floorArea: "",
        mtArea: "",
        kng: "",
        floc: "",
        sap: "",
        type: "",
        propertySheetAppellation: "",
        protection: "",
        protectionNote: "",
        numberDedicated: "",
        numberCanBeSeated: "",
        numberSeated: "",
        classification: "",
        status: "",
        solarPanel: "",
        fuelCellGenerator: "",
        dieselGenerator: "",
        energyTradingPartner: "",
      },
      address: {
        country: "",
        postalCode: "",
        county: "",
        city: "",
        publicAreaName: "",
        publicAreaType: "",
        houseNumber: "",
        stairWay: "",
        floor: "",
        door: "",
        parcelNumber: "",
        gpsCoordinates: "",
        notes: "",
      },
    };

    state.value.propertyAddress.forEach((e) => {
      hermes.address[e.model] = e.value;
    });

    state.value.propertyBasicData.forEach((e) => {
      hermes.basicData[e.model] = e.value;
    });

    

    if (state.value.propertyId != 0) {
      await Axios.put(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
          "/rem/property/" +
          state.value.propertyId,
        hermes,
        {
          onTokenRefreshed: () => {
            this.saveProperty(state, t);
          },
        }
      ).then((resp) => {
        if (resp) {
          if (resp.status == 200) {
            toast.success(t("message.successfulSave"));
            state.value.propertyAddress.forEach((e) => {
              e.value = "";
            });
            state.value.propertyBasicData.forEach((e) => {
              e.value = "";
            });
            state.value.tab = 1;
            state.value.propertyId = 0;
          } else {
            toast.error(t("message.notSuccessfulSave"));
          }
        } else {
          toast.error(t("message.notSuccessfulSave"));
        }
      });
    } else if(hermes.address.country != null){
      await Axios.post(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/property",
        hermes,
        {
          onTokenRefreshed: () => {
            this.saveProperty(state, t);
          },
        }
      ).then((resp) => {
        if (resp) {
          if (resp.status == 201) {
            toast.success(t("message.successfulSave"));
            state.value.propertyAddress.forEach((e) => {
              e.value = "";
            });
            state.value.propertyBasicData.forEach((e) => {
              e.value = "";
            });
            state.value.tab = 1;
            state.value.propertyId = 0;
          } else {
            toast.error(t("message.notSuccessfulSave"));
          }
        } else {
          toast.error(t("message.notSuccessfulSave"));
        }
      });
    }
  },
};
