import Axios from "@/misc/axios";
import toast from "@/misc/toast.js";
//import { watchEffect } from "vue";

import remFormUtils from "../../misc/remFormUtils";

import TenantConfigHandler from "@/misc/TenantConfigHandler";

export default {
  // setup scripts

  setup: (/*state*/) => {
    /*watchEffect(() => {
      if (state.value.partnerData[1].value) {
        setTimeout(() => {
          state.value.partnerData[2].value =
            "CHANGECODE###" + state.value.partnerData[1].value;
        }, 700);

      }

      if (state.value.partnerData[3].value) {
        //console.log("isperson");

        setTimeout(() => {
          state.value.isPerson =
            state.value.partnerData[3].value == "true" ? true : false;
          if (state.value.isPerson == true) {
            state.value.partnerData[5].value = "";
          } else {
            state.value.partnerData[4].value = "";
          }
        }, 700);
      }

      if (state.value.addressData[1].value) {
        //console.log("postal");
        setTimeout(() => {
          state.value.addressData[3].value =
            "CHANGECODE###" + state.value.addressData[1].value;
          state.value.addressData[4].value =
            "CHANGECODE###" + state.value.addressData[1].value;
        }, 700);
      }
    });*/
  },
  getOptions: async function (
    source,
    target,
    schema,
    isThereWeirdFormattingForOption
  ) {
    schema.forEach((e) => {
      if (e.type == "label") {
        target.push(e);
        return;
      }

      const hermes = e;
      if (hermes.type == "option" && isThereWeirdFormattingForOption == true) {
        if (source[e.model + "Id"]) {
          hermes.value = source[e.model + "Id"];
          hermes.valueLabel = source[e.model + "Value"];
        }
      } else {
        hermes.value = source[e.model];
      }
      target.push(hermes);
    });
  },

  //savers

  savePartner: async function (state, t) {
    const hermes = {
      partnerMainType: 0,
      partnerSubType: 0,
      name: "",
      taxId: "",
      taxNumber: "",
      partnerAddresses: [],
    };

    state.value.partnerData.forEach((e) => {
      if (e.model != "") {
        hermes[e.model] = e.value;
        
      }
    });

    if (state.value.partnerId != 0) {
      hermes.id = state.value.partnerId;
    }

    if(hermes.taxNumber == ""){
      hermes.taxNumber == null;
    }

    

    remFormUtils.putPost(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/partner",
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/partner/" +
        state.value.partnerId,
      hermes,
      "partner",
      1,
      state,
      t
    );
  },
  saveAddress: async function (state, t) {
    if (state.value.partnerId == 0) return;

    const hermes = {};

    (hermes.addressType = "POSTAL"),
      state.value.addressData.forEach((e) => {
        hermes[e.model] = e.value;
      });
    hermes.partnerId = state.value.partnerId;

    remFormUtils.putPost(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/partner/partner-address",
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/partner/partner-address/" +
        state.value.addressId,
      hermes,
      "address",
      3,
      state,
      t
    );
  },
  saveContact: async function (state, t) {
    if (state.value.partnerId == 0) return;

    const hermes = {};
    

    state.value.contactData.forEach((e) => {
      hermes[e.model] = e.value;
    });

    hermes.partnerId = state.value.partnerId;

    remFormUtils.putPost(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/partner/contact",
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/partner/contact/" +
        state.value.contactId,
      hermes,
      "contact",
      5,
      state,
      t
    );
  },

  deleteUniversal: async function(state, endpoint, formdata, tab, t){
    try {
      let resp = await Axios.delete(
        endpoint,
        {
          onTokenRefreshed: () => {
            this.deleteUniversal(state, endpoint, formdata, tab, t);
          },
        }
      )
        if (resp && resp.status== 204) {
          toast.success(t("message.deleteSuccessful"))
          state.value.tab = tab
          state.value[`${formdata}Id`] = 0;
        state.value[`${formdata}Edit`] = false
        } else {
          toast.error(t("message.deleteNotSuccessful"))
        }
      
    } catch (e) {
      toast.error(t("message.deleteNotSuccessful"))
    }
  }
 
};
