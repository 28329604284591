import router from "../../misc/router"


export default {
    checkAccess(state, view){
        const newStates = ["new", "newfromlist"]
        if(state.value.taskId == 0 && !newStates.includes(view) ){
            router.push({
                name:'permissionDenied'
            })
        }

        if(state.value.taskId != 0 && newStates.includes(view)){
            router.push({
                name:'permissionDenied'
            })
        }
    },
    editButtonConfig: {
        viewList: ['read'],
        stateList: [],
        readonlyViewList: [],
        readonlyStateList: [],
    },
    cancelButtonConfig: {
        viewList: ['edit'],
        stateList: [],
        readonlyViewList: [],
        readonlyStateList: [],
    },
    relatedTaskConfig: {
        viewList: ['new', 'read' ],
        stateList: [],
        readonlyViewList: [],
        readonlyStateList: [],
    },
    activitiesConfig: {
        viewList: ['read',],
        stateList: [],
        readonlyViewList: [],
        readonlyStateList: [],
    }
}