<template>
    <div :class="{ hideOpenIndicator: inputValue }">
        <div v-if="searchable == true" class="dropdown">
            <!-- <input type="hidden" v-model="inputValue" class="input input-sm input-bordered w-full"
                :placeholder="$t('message.select')"> -->
            <div class="im-dropdown w-full ">
                <input type="text" list="optionList" v-model="optionSearchValue" @focusin="focus = true"
                    @focusout="setFocus" class="input input-sm input-bordered w-full" :mandatory="mandatory"
                    :id="`${title}${type}`" :disabled="disabled">

                <div class="relative">
                    <ul class="im-dropdown-content bg-base-100 rounded-box z-50 w-full shadow z-40"
                        v-if="focus == true">
                        <!-- <li class="option-hover"><a @click="optionSearchValue = ''; inputValue = ''">{{
                            $t('message.select') }}</a></li> -->
                        <li class="option-hover" v-for="op in options" v-bind:key="op.value + op.label"
                            @click="optionSearchValue = op.label; inputValue = op.value">{{ op.label }}</li>
                    </ul>
                </div>

            </div>
            <button v-if="inputValue" class="clear-button" @click="optionSearchValue = ''; inputValue = ''"
                aria-label="Törlés">
                <span class="text-sm bold">
                    X
                </span>
            </button>
        </div>
        <div v-else >
            <div v-if="props.type == 'optionManual'" class="dropdown">
                <select v-model="inputValue" class="input input-sm input-bordered w-full pr-2" :mandatory="mandatory"
                    @focusout="setFocus" :id="`${title}${type}`" :disabled="disabled">
                    <!-- <option value="" selected hidden>{{ $t('message.select') }}</option> -->
                    <!-- <option value="null">{{ $t('message.empty') }}</option> -->

                    <option v-for="op in options" v-bind:key="op.value + op.label" :value="op.value"
                        :parent="op.parent">{{
                            t(`message.${op.label}`) }}
                    </option>
                </select>
                <button v-if="inputValue" class="clear-button" @click="inputValue = ''" aria-label="Törlés">
                    <span class="text-sm bold">
                        X
                    </span>
                </button>
            </div>
            <div v-else class="dropdown">
                <select v-model="inputValue" class="input input-sm input-bordered w-full pr-2" :mandatory="mandatory"
                    @focusout="setFocus" :id="`${title}${type}`" :disabled="disabled">
                    <!-- <option value="" selected hidden>{{ $t('message.select') }}</option> -->
                    <!-- <option value="null">{{ $t('message.empty') }}</option> -->

                    <option v-for="op in options" v-bind:key="op.value + op.label" :value="op.value"
                        :parent="op.parent">{{
                            op.label }}
                    </option>
                </select>
                <button v-if="inputValue" class="clear-button" @click="inputValue = ''" aria-label="Törlés">
                    <span class="text-sm bold">
                        X
                    </span>
                </button>
            </div>

        </div>
    </div>
</template>

<script setup>
import { defineModel, onMounted, ref, computed, watch } from 'vue';
import Axios from "@/misc/axios";
import TenantConfigHandler from '@/misc/TenantConfigHandler';
import store from "@/misc/vuex-store";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const props = defineProps(['title', 'type', 'valueLabel', 'optionValue', 'endPoint', 'displayValue', 'searchable', 'manualOptions', 'onchangeMethod', 'validationMethod', 'mandatory', 'disabled']);

const inputValue = defineModel('inputValue')
const parent = defineModel('parent')
const validated = defineModel('validated')
const inputError = defineModel('inputError')
const optionsOut = defineModel('optionsOut')

const options = ref([])
//options.value = [];

let optionsRepo = [];

const optionSearchValue = ref(props.valueLabel);

const focus = defineModel('focus');

const language = computed(() => store.getters.getLanguage);

onMounted(() => {
    getOptions();
})

const getOptions = async () => {

    if (props.type == "option" && props.optionValue) {

        const resp = await Axios.get(
            TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
            "/rem/option/options/" +
            props.optionValue,
            {
                'Accept-Language': language.value
            },
            {
                onTokenRefreshed: () => {
                    getOptions();
                },
            }
        )
        if (resp && resp.data) {
            resp.data.forEach((op) => {
                options.value.push({
                    label: op.value,
                    value: op.id,
                    parent: op.parent != null ? op.parent.id : "",

                });
            });
        }


    } else if ((props.type == "optionapi" || props.type == "optionWeirdApi") && props.endPoint) {


        const resp = await Axios.get(
            props.endPoint,
            {
                page: 0,
                size: 1,
                sort: ["id"],
            },
            {
                onTokenRefreshed: () => {
                    getOptions();
                },
            }
        )
        if (resp.data && resp.data.content) {

            resp.data.content.forEach((op) => {

                options.value.push({
                    label: props.type == "optionapi" ? op[props.displayValue] : op.name,
                    value: op.id,
                });

            });
        }


    } else if (props.type == "optionapi" && !props.endPoint && inputValue.value != "") {
        options.value.push({
            label: inputValue.value,
            value: inputValue.value
        });
        optionsRepo.push({
            label: inputValue.value,
            value: inputValue.value
        });


    } else if (props.type == "optionManual" && props.manualOptions) {
        options.value = props.manualOptions;
        
    }

    optionsRepo = options.value
    optionsOut.value = options.value

    if (parent.value != "") {

        if (props.onchangeMethod) {
            props.onchangeMethod(options, optionsRepo, parent.value, inputValue, inputError)
            optionsOut.value = options.value
        }
    }
    if (props.mandatory == true && (inputValue.value == "" || inputValue.value == null)) {


        validated.value = false
    } else {

        validated.value = true;
    }
}

const setFocus = function () {
    setTimeout(() => { focus.value = false }, 500);
}

watch(optionSearchValue, (newValue, oldValue) => {
    if (newValue == "") {
        options.value = optionsRepo
        optionsOut.value = options.value
    }
    else if (oldValue != newValue) {
        options.value = optionsRepo.filter(elem => (elem.label.toLowerCase()).includes(newValue.toLowerCase()));
        optionsOut.value = options.value
    }
    // console.log(newValue + " " + oldValue);

})

watch(() => parent.value, (newValue) => {
    if (props.onchangeMethod && (newValue != null && newValue != "")) {
        props.onchangeMethod(options, optionsRepo, newValue, inputValue, inputError)
        optionsOut.value = options.value
    }

    if (props.mandatory && inputValue.value == "" && parent == "") {
        validated.value = false
    } else {
        validated.value = true;
    }
})

watch(() => inputValue.value, (newValue, oldValue) => {


    if (props.mandatory && inputValue.value == "") {
        validated.value = false

    } else {
        validated.value = true;

    }



    if (props.validationMethod && newValue != '' && newValue != oldValue) {
        props.validationMethod(options, optionsRepo, "", newValue, oldValue, inputValue, inputError)
        if (inputError.value == "") {
            validated.value = true
        } else {
            validated.value = false
        }
    }


})
</script>


<style scoped>
.im-dropdown-content {
    position: absolute
}

.im-invisible {
    display: none;

}

.dropdown {
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 100%;
}

.clear-button {
    position: absolute;
    right: 0.6rem;
    background: none;
    border: none;
    color: rgb(175, 170, 170);
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    line-height: 1;
}

.clear-button:hover {
    cursor: pointer;
}

.hideOpenIndicator .dropdown::before {
    display: none;
}

.dropdown::before {
    position: absolute;
    font-size: 12px;
    content: " \25BC";
    top: 7px;
    right: -8px;
    height: 20px;
    width: 28px;
    pointer-events: none;
}

select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.option-hover {
    padding: 5px 0 5px 5px;
    font-size: 0.875rem;

}

.option-hover:hover {
    background-color: rgb(147, 185, 247);
    color: #242933;
}

ul .option-hover:first-of-type {
    border-radius: 10px 10px 0 0;
}

ul .option-hover:last-of-type {
    border-radius: 0 0 10px 10px;
}
</style>