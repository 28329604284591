<!------------
README

<ImFormInput 
v-model:inputValue="VARIABLE FOR TWO WAY BINDING WITH THE INPUT VALUE" 
v-model:parent="change this variable to trigger onchange method"
v-model:validated="signals if the field is validated"

type="THE TYPE OF THE INPUT FIELD EG. TEXT, NUMBER, EMAIL
    UNIQUE VERSION ARE 
        LABEL - FORM SECTION LABEL WITH HORIZONTAL RULE
        CHECKBOX - DISPLAYED AS A YES-NO SELECT
        OPTION - FETCHES AZ OPTIONVALUE
            USE THE OPTIONVALUE FIELD TO DEFINE THE OPTIONVALUE TYPE
        OPTIONAPI - FETCHES VALUES FROM A DEDICATED ENDPOINT
            USE THE ENDPOINT FIELD TO DEFINE THE ENDPOINT 
            THE VALUE OF THE OPTIONS WILL BE THE RECORD ID
            USE THE DISPLAYVALUE FIELD TO DEFINE THE OPTIONS LABEL FIELD" 
        OPTIONMANUAL - GIVE THE OPTIONS IN AN ARRAY TROUGH THE manualOptions PROPERTY
        OPTIONSEARCHENDPOINT - USES THE OptionSearchEndpoint COMPONENT

title="LABEL OF THE INPUT FIELD" 
manualOptions="LIST OF THE OPTIONS FOR OPTIONMANUAL TYPE" 
    format: [
        {
            label: LABEL_OF_OPTION,
            value: VALUE_OF_OPTION,
        },
        {
            label: LABEL_OF_OPTION,
            value: VALUE_OF_OPTION,
        }
    ]

mandatory="is the field mandatory"

valueLabel="LABEL OF THE INITIAL VALUE"
searchable="SEARCH BETWEEN THE OPTIONS (OPTION AND OPTIONAPi ONLY)"

searchable="TRUE / FALSE - ENABLES SEARCHING IN THE OPTIONS"

optionValue="OPTIONVALUE TO FETCH THE OPTIONS FROM E.G. DOCUMENT_TYPE (ONLY FOR OPTIONVALUE)"   

endPoint="ENDPOINT TO FETCH THE OPTIONS FROM" 
displayValue="WHICH FIELD SHOULD BE THE LABEL OF THE OPTION (ONLY FOR OPTIONAPI)"

onchangeMethod= (options, optionsRepo, value, newValue, oldValue, inputValue, inputError) {}
reacts to v-model:parent change

validationMethod= (options, optionsRepo, value, newValue, oldValue, inputValue, inputError) {}
reacts to inputValue change
>
</ImFormInput>


-->

<template>



    <div>

        <SVGRenderer v-if="config.helperText" svgKey="help_icon" class="w-4 h-4 pl-2 tooltip tooltip-right"
            :data-tip="config.helperText" />
        <div v-if="editing == false" class="wf-field-title " @click="setEditingTrue()" :class="{ 'wf-title-hover': readonly == false || readonly == undefined}">
            <span class="text-white w-full " :class="{ 'text-md': !textsm, 'font-bold': !textsm }">{{ (displayValue ==
                ""
                || displayValue == null) ? $t(`message.${config.title}`) : displayValue }}</span>
            <span v-if="config.mandatory && route.params.view != 'read'" :class="{ 'im-mandatory': inputValue == '' && validated == false }">{{ " *"
                }}</span>
            <SVGRenderer v-if="config.helperText" svgKey="help_icon" class="w-4 h-4 pl-2 tooltip tooltip-right"
                :data-tip="config.helperText" />
        </div>

        <div v-if="editing == true">
            <!-- eslint-disable -->
            <RemFormInput v-model:inputValue="inputValue" v-model:parent="parent" v-model:validated="validated"
                v-model:focus="focus" v-model:options="options" :config="config"></RemFormInput>
            <!-- eslint-enable -->
        </div>

    </div>
</template>

<script setup>
import RemFormInput from '../imFormInput/RemFormInput.vue';

import { defineModel, ref, onMounted, watch } from 'vue';
import SVGRenderer from '../../SVGRenderer.vue';
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
let route = useRoute();

const { t } = useI18n();


const props = defineProps(['config', 'textsm']);
const inputValue = defineModel('inputValue');
const parent = defineModel('parent')
const validated = defineModel('validated')
const readonly = defineModel('readonly')
if (readonly.value == undefined) {
    readonly.value = false
}

const options = defineModel('options')

const editing = ref(false);
const focus = ref(false);
const displayValue = ref("")
let onSetup = true;

const config = {
    title: props.config.title,
    type: props.config.type,
    valueLabel: props.config.valueLabel,
    optionValue: props.config.optionValue,
    endPoint: props.config.endPoint,
    displayValue: props.config.displayValue,
    searchable: props.config.searchable,
    manualOptions: props.config.manualOptions,
    onchangeMethod: props.config.onchangeMethod,
    validationMethod: props.config.validationMethod,
    optionSearchEndpoint: props.config.optionSearchEndpoint,
    mandatory: props.config.mandatory

}



onMounted(() => {
    setTimeout(() => {
        displayValue.value = getDisplayValue(1)
    }, 1000)
    
    if (props.config.mandatory && (inputValue.value == "" || inputValue.value == null)) {
        
        validated.value = false;
    } else {
        validated.value = true;
    }
})



const setEditingTrue = function () {
    if (readonly.value == true) {

        return
    }
    editing.value = true
    focus.value = true
    if (props.config.type == 'optionSearchEndpoint') {
        return
    }
    setTimeout(() => {
        document.getElementById(`${props.config.title}${props.config.type}`).focus()
    }, 400);

}



watch(() => inputValue.value, () => {


    displayValue.value = getDisplayValue()
    if (props.mandatory && inputValue.value == "" && parent == "") {
        validated.value = false
    } else {
        validated.value = true;
    }
})

const getDisplayValue = function (firstRun) {

    if (inputValue.value == "" || inputValue.value == null || inputValue.value == 'null') {
        return props.title
    }
    if (props.config.type == 'option' || props.config.type == 'optionapi' || props.config.type == 'optionWeirdApi') {
        
        
        if ((firstRun || onSetup == true) && props.config.valueLabel != "") {
            
            onSetup = false;
            return props.config.valueLabel
        }
        let label = t(`message.${props.config.title}`)
        options.value?.forEach((e) => {
            if (e.value == inputValue.value) {
                label = e.label
            }
        })
        config.valueLabel = label;
        return label

    }
    if (props.config.type == 'optionManual') {

        let label = t(`message.${props.config.title}`)
        props.config.manualOptions.forEach((e) => {
            if (e.value + '' == inputValue.value + '') {
                label = e.label
            }
        })
        config.valueLabel = label;
        return label

    }

    if (props.config.type == 'checkbox' || props.config.type == 'toggle') {
        return inputValue.value == true ? t('message.yes') : t('message.no')
    }
    if (props.config.type == 'optionSearchEndpoint') {

        if ((firstRun || onSetup == true) && config.valueLabel != "") {
            onSetup = false;
            return config.valueLabel
        } else {
            setTimeout(() => {

                if (document.querySelector(`#${props.config.title}${props.config.type} .vs__dropdown-toggle .vs__selected-options .vs__selected`)) {
                    displayValue.value = document.querySelector(`#${props.config.title}${props.config.type} .vs__dropdown-toggle .vs__selected-options .vs__selected`).innerHTML.replace('<!---->', '')
                    config.valueLabel = displayValue.value;
                }

            }, 200)

            return;
        }



    }
    if (props.config.type == 'date') {
        return new Date(inputValue.value).toLocaleDateString()
    }

    if (props.config.type == 'datetime-local') {
        return new Date(inputValue.value).toLocaleString()
    }

    return inputValue.value;

}

watch(() => parent.value, () => {
    displayValue.value = getDisplayValue()


})

watch(() => focus.value, (newValue) => {


    if (newValue == false) {
        setTimeout(() => {

            editing.value = false
        }, 500)
    }

})

</script>

<style scoped>
.wf-field-title {

    padding: 10px 0 10px 0;
    border-radius: 10px;
}

.wf-title-hover:hover {
    background-color: rgba(0, 0, 0, .3);

}

.im-dropdown-content {
    position: relative
}

.im-invisible {
    display: none;

}

.im-mandatory {
    color: red;
}
</style>